<template>
  <div class="BackstageNoticeList-wrapper">
    <list ref="list"
          exportPermission="export"
          exportMethod="delay"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers"
          :isMultiSelect="true">
      <template #headerSlot>
        <v-button text="新增网格通知"
                  permission="gridAdd"
                  @click="create(21)"></v-button>
        <v-button text="新增项目通知"
                  permission="add"
                  @click="create(6)"></v-button>
        <v-button text="新增商业通知"
                  permission="bussinessAdd"
                  @click="create(5)"></v-button>
        <v-button text="新增商家通知"
                  permission="bussinessAdd"
                  @click="create(23)"></v-button>
      </template>
      <template #searchSlot>
        <v-datepicker label="创建时间段"
                      type="rangedatetimer"
                      defaultTimeType="week"
                      :startTime.sync="searchParams.startTime"
                      :endTime.sync="searchParams.endTime"
                      :maxDate="maxDate"></v-datepicker>
        <v-select label="发布范围"
                  v-model="searchParams.sendType"
                  :options="sendTypeOps"
                  :width="200"
                  @change="changeSendType"></v-select>
        <v-select label="发布类型"
                  v-model="searchParams.appType"
                  :options="appTypeOps"
                  @change="changeAppType"></v-select>
        <v-select2 ref="communitySelect2"
                   label="发布对象"
                   placeholder="查询组织"
                   v-if="searchParams.sendType===2 ||searchParams.sendType===4"
                   v-model="searchParams.receiveId"
                   v-bind="communityParams"></v-select2>
        <v-select2 ref="sysOrgIdSelect2"
                   label="发布对象"
                   :placeholder="`查询${showOrgName}`"
                   v-if="searchParams.sendType===5 || searchParams.sendType===6"
                   v-model="searchParams.sysOrgId"
                   v-bind="sysOrgIdParams"></v-select2>
        <v-input label="接受对象手机号"
                 v-if="searchParams.sendType===1"
                 v-model="searchParams.receiveMobile"></v-input>
        <v-datepicker label="发布时间段"
                      type="rangedatetimer"
                      :startTime.sync="searchParams.pushStartTime"
                      :endTime.sync="searchParams.pushEndTime"></v-datepicker>
        <v-input label="标题"
                 v-model="searchParams.noticeTitle"></v-input>
        <v-select label="跳转类型"
                  v-model="searchParams.skipType"
                  :options="skipTypeOps"></v-select>
        <!-- <v-select label="通知类型"
                  v-model="searchParams.noticeTypeForSearch"
                  :options="noticeTypeOps"></v-select> -->
        <v-input label="发布人"
                 v-model="searchParams.inuser"></v-input>
        <v-select label="发布人归属类型"
                  v-model="searchParams.noticeLevel"
                  :options="noticeLevelOps"
                  @change="changeNoticeLevel"></v-select>
        <v-select2 ref="organizeSelect2"
                   label="发布人归属"
                   placeholder=" "
                   v-model="searchParams.organizeId"
                   v-bind="organizeIdParams"></v-select2>
        <v-select v-if="searchParams.noticeTypeForSearch===1"
                  label="信息签收"
                  v-model="searchParams.sign"
                  :options="signOps"></v-select>
        <v-select v-if="searchParams.noticeTypeForSearch===1"
                  label="签收状态"
                  v-model="searchParams.signStatus"
                  :options="signStatusOps"></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button text="删除"
                  type="text"
                  permission="delete"
                  @click="deleteItem(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量删除"
                  permission="delete"
                  @click="batchDelete(scope.selectedData)"></v-button>
      </template>
    </list>
    <el-dialog :title="dialogTitle"
               width="500px"
               :visible.sync="dialogVisible">
      <div class="dialog-content">
        <div class="dialog-h5"
             v-html="dialogContent"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getListURL, exportListURL, getOrganizeURL, getSysOrgURL, deleteNoticeURL, batchDeleteNoticeUrl } from './api'
import { noticeTypeOps, noticeLevelOps, appTypeOps, signOps, signStatusOps } from './map'
import { createAlinkVNode } from 'common/vdom'
import { communityParams } from 'common/select2Params'

export default {
  name: 'BackstageNoticeList',
  data () {
    let _this = this
    return {
      dialogTitle: '',
      dialogVisible: false,
      dialogContent: '',
      maxDate: new Date(),
      searchUrl: getListURL,
      exportUrl: exportListURL,
      deleteNoticeURL,
      batchDeleteNoticeUrl,
      communityParams,
      skipTypeOps: [
        {
          text: '全部',
          value: undefined
        },
        {
          text: '至详情页',
          value: 1
        },
        {
          text: '至功能页',
          value: 2
        },
        {
          text: '至外部链接',
          value: 3
        }
      ],
      noticeTypeOps: noticeTypeOps(),
      noticeLevelOps: noticeLevelOps(1),
      appTypeOps: appTypeOps(),
      signOps: signOps(1),
      signStatusOps: signStatusOps(1),
      needClearOrgnizationId: true,
      organizeIdParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: 'orgName_',
          value: 'id'
        },
        response: {
          text: 'name',
          value: 'id'
        },
        subjoin: {
          roleType: undefined
        },
        disabled: true,
        method: 'post'
      },
      sysOrgIdParams: {
        searchUrl: getSysOrgURL,
        request: {
          text: 'orgName',
          value: 'id'
        },
        response: {
          text: 'orgName',
          value: 'id'
        },
        method: 'get'
      },
      searchParams: {
        noticeTitle: '',
        inuser: '',
        skipType: undefined,
        noticeTypeForSearch: 3,
        noticeLevel: undefined,
        sendType: 2,
        appType: 1,
        startTime: '',
        endTime: '',
        pushStartTime: '',
        pushEndTime: '',
        receiveMobile: '',
        organizeId: undefined,
        receiveId: undefined,
        sign: undefined
      },
      headers: [
        {
          prop: 'noticeTitle',
          label: '通知标题',
          formatter: (row, prop) => {
            let opts = {
              text: row.noticeTitle,
              cb: _this.showNoticeContent
            }
            return createAlinkVNode(this, row, prop, opts)
          }
        },
        {
          prop: 'skipType',
          label: '跳转类型'
        },
        {
          prop: 'inuser',
          label: '发布人'
        },
        {
          prop: 'noticeLevel',
          label: '发布人归属类型'
        },
        {
          prop: 'orgName',
          label: '发布人归属'
        },
        {
          prop: 'noticeType',
          label: '通知类型'
        },
        {
          prop: 'sendTypeName',
          label: '发布范围'
        },
        {
          prop: 'receiveName',
          label: '发布对象'
        },
        {
          prop: 'readCount',
          label: '阅读数',
          align: 'right'
        },
        {
          prop: 'sign',
          label: '信息签收'
        },
        {
          prop: 'signStatus',
          label: '签收状态'
        },
        {
          prop: 'signSummary',
          label: '签收概况',
          formatter: (row, prop) => {
            if (row.sign === '否' || row.sign === '/' || row.sendType === '1') {
              return row.signSummary
            } else {
              let opts = {
                text: row.signSummary,
                cb: _this.goToPage
              }
              return createAlinkVNode(this, row, prop, opts)
            }
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'actualPushTime',
          label: '发布时间'
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.changeAppType(null, null, true)
      if (vm.searchParams.noticeLevel) {
        vm.needClearOrgnizationId = false
        vm.organizeIdParams.subjoin = {
          limitCnt: 20,
          roleType: vm.searchParams.noticeLevel
        }
      }
      vm.organizeIdParams.disabled = !vm.searchParams.noticeLevel
    })
  },
  created () {
    this.searchParams.sendType = this.$store.state.userInfo.regionType == '3' ? 1 : 2
  },
  computed: {
    showOrgName () {
      return this.$store.state.baseConfig.docConfig.ORG_NAME || ''
    },
    sendTypeOps () {
      let regionType = this.$store.state.userInfo.regionType
      let ORG_NAME = this.$store.state.baseConfig.docConfig.ORG_NAME
      let appType = this.searchParams.appType
      let tempSendTypeOps = []
      if (regionType != '3') {
        if (appType === 2) {
          tempSendTypeOps = [
            {
              text: '项目',
              value: 2
            },
            {
              text: '个人',
              value: 1
            }

          ]
        } else {
          tempSendTypeOps = [
            {
              text: '项目',
              value: 2
            },
            {
              text: '个人',
              value: 1
            },
            {
              text: '全体用户',
              value: 3
            },
            {
              text: '楼幢',
              value: 4
            },
            {
              text: `${ORG_NAME}`,
              value: 5
            },
            {
              text: `${ORG_NAME}超管`,
              value: 6
            },
            {
              text: '项目内企业管理员',
              value: 50
            },
            {
              text: '用户标签',
              value: 7
            }
          ]
        }
      } else {
        if (appType === 2) {
          tempSendTypeOps = [
            {
              text: '个人',
              value: 1
            }

          ]
        } else {
          tempSendTypeOps = [
            {
              text: '个人',
              value: 1
            },
            {
              text: '全体用户',
              value: 3
            },
            {
              text: '楼幢',
              value: 4
            }
          ]
        }
      }
      if(this.searchParams.appType === 3){
         tempSendTypeOps = [
            {
              text: '商家',
              value: 8
            },
            {
              text: '门店',
              value: 9
            }

          ]
      }
      return tempSendTypeOps
    },

    sKipTypeOps () {
      let appType = this.searchParams.appType
      let tempSendTypeOps = []
      if (appType === 2) {
        tempSendTypeOps = [
          {
            text: '全部',
            value: undefined
          },
          {
            text: '至详情页',
            value: 1
          },
          {
            text: '至外部链接',
            value: 3
          }
        ]
      } else {
        tempSendTypeOps = [
          {
            text: '全部',
            value: undefined
          },
          {
            text: '至详情页',
            value: 1
          },
          {
            text: '至功能页',
            value: 2
          },
          {
            text: '至外部链接',
            value: 3
          }
        ]
      }

      return tempSendTypeOps
    }
  },
  methods: {
    create (noticeType) {
      this.$router.push({
        name: 'backstageNoticeForm',
        query: {
          noticeType
        }
      })
    },
    async deleteItem (row) {
      let _this = this
      let isOk = await this.$confirm('确认删除？')
      if (isOk) {
        let url = `${deleteNoticeURL}?noticeId=${row.noticeId}&sendType=${row.sendType}&dataObject=${row.noticeTitle}`
        this.$axios.delete(url).then(async res => {
          if (res.status === 100) {
            _this.$refs.list.searchData()
            _this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
          }
        })
      }
    },
    async batchDelete (selected) {
      if (selected.data.length <= 0) {
        this.$message('请选择通知')
        return
      }
      let isOk = await this.$confirm('确认删除？')
      if (isOk) {
        let list = selected.data.map(item => {
          return {
            noticeId: item.noticeId,
            sendType: item.sendType
          }
        })
        let _this = this
        this.$axios.post(batchDeleteNoticeUrl, {
          list: list
        }).then(res => {
          if (res.status === 100) {
            _this.$refs.list.searchData()
            _this.$message.success('操作成功')
          }
        })
      }
    },
    showNoticeContent (row, title) {
      this.dialogVisible = true
      this.dialogTitle = title
      this.dialogContent = `<div>${row.noticeContent}</div>`
    },
    goToPage (row, text) {
      this.$router.push({
        name: 'signOverviewList',
        query: {
          signNo: row.sendType === '1' ? row.noticeSignNo : row.id,
          receiveUser: row.receiveUser,
          sendType: row.sendType,
          appType: 1,
          noticeTypeForSearch: 3
        }
      })
    },
    changeNoticeLevel (val, item) {
      let noticeLevel = this.searchParams.noticeLevel || undefined
      if (this.needClearOrgnizationId) {
        this.$refs.organizeSelect2.clearValue()
      }
      this.organizeIdParams.disabled = !noticeLevel
      this.organizeIdParams.subjoin.roleType = noticeLevel
      this.needClearOrgnizationId = true
    },
    // 切换发布范围时，清空接受对象手机号输入框、清空发布对象项目选择框、清空发布对象组织选择框
    changeSendType (val, item, beforeRouteEnter) {
      if (this.$refs.communitySelect2) {
        this.$refs.communitySelect2.clearValue()
      }
      if (this.$refs.sysOrgIdSelect2) {
        this.$refs.sysOrgIdSelect2.clearValue()
      }
      this.searchParams.receiveId = undefined
      this.searchParams.sysOrgId = undefined
      this.communityParams.receiveMobile = undefined
    },
    changeAppType (val, item, beforeRouteEnter) {
      this.searchParams.skipType = undefined
      this.searchParams.sendType = beforeRouteEnter ? this.searchParams.sendType : 1
      if(this.searchParams.appType === 3){
        this.searchParams.sendType = 8
      }
    }
  }
}
</script>
<style scoped>
.dialog-content {
  height: 400px;
  margin-top: -20px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-right: 10px;
}

.dialog-h5 >>> img,
p,
span {
  max-width: 100%;
}
</style>
